import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.checkImage();
  }

  checkImage() {
    const img = new Image();
    img.src = this.element.src;

    img.onload = () => {
      // Image loaded successfully, do nothing
    };

    img.onerror = () => {
      // Image failed to load, use fallback
      this.element.src = this.element.dataset.fallback;
    };
  }
}
