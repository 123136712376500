import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    // Don't open by default
    // this.element.style.display = "none";
    this.element.ctrl = this;
  }

  disconnect() {
    // Re-enable scrolling when modal is closed
    document.body.style.overflow = "auto";
  }

  open() {
    // Show modal and prevent scrolling
    this.element.style.display = "flex";
    document.body.style.overflow = "hidden";
  }

  close(event) {
    // Remove modal element from DOM
    this.element.style.display = "none";
    document.body.style.overflow = "auto";
  }
}
