import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["style", "nextButtonDefault", "nextButtonLoader"];

  connect() {
    this.selectedStyle = null;
  }

  selectStyle(event) {
    const selectedElement = event.currentTarget;
    const styleValue = selectedElement.dataset.lp2Value;

    // Remove styling from previously selected style
    if (this.selectedStyle) {
      this.selectedStyle.classList.remove("border-rose-500");
      this.selectedStyle.classList.add("border-white/30");
      this.selectedStyle.classList.remove("opacity-100");
      this.selectedStyle.classList.add("opacity-30");
    }

    // Apply styling to newly selected style
    selectedElement.classList.add("border-rose-500");
    selectedElement.classList.remove("border-white/30");
    selectedElement.classList.remove("opacity-30");
    selectedElement.classList.add("opacity-100");

    // Set the other style to low opacity
    this.styleTargets.forEach((target) => {
      if (target !== selectedElement) {
        target.classList.remove("border-rose-500");
        target.classList.remove("opacity-100");
        target.classList.add("opacity-30");
      }
    });

    this.selectedStyle = selectedElement;

    // Automatically show loader and submit style
    this.showLoader();
    this.submitStyle();
  }

  showLoader() {
    this.nextButtonDefaultTarget.classList.add("hidden");
    this.nextButtonLoaderTarget.classList.remove("hidden");
  }

  async submitStyle() {
    if (!this.selectedStyle) return;

    const style = this.selectedStyle.dataset.lp2Value;

    window.plausible &&
      plausible("Style Selected", {
        props: {
          style,
        },
      });

    try {
      const response = await fetch("/cmai_redirect", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
            .content,
        },
        body: JSON.stringify({
          data: { _step: "style", style },
          from_path: window.location.pathname,
          visit_params: JSON.parse(
            document.body.getAttribute("data-visit-params")
          ),
        }),
      });

      const data = await response.json();
      window.location.href = data.url;
    } catch (error) {
      console.error("Error:", error);
      this.hideLoader();
    }
  }

  hideLoader() {
    this.nextButtonDefaultTarget.classList.remove("hidden");
    this.nextButtonLoaderTarget.classList.add("hidden");
  }
}
