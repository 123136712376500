import { application } from "./application";
// import { eagerLoadControllersFrom } from "@hotwired/stimulus-loading";

import ImageFallbackController from "./image_fallback_controller";

import AuthModalController from "./auth_modal_controller";

import Lp2Controller from "./lp2_controller";
// import Lp3Controller from "./lp3_controller";

const lpId = document.body.dataset.controller;

application.register("image-fallback", ImageFallbackController);
application.register("auth-modal", AuthModalController);
application.register("lp2", Lp2Controller);
// application.register("lp3", Lp3Controller);
